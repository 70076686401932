<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <reports-sidebar :tabs="tabs" />
        </div>
        <div class="col-md-9">
          <div class="tab-content">
            <div
              class="tab-pane fade show"
              :class="tabs.overview ? ' active' : ''"
            >
              <reports-overview v-if="tabs.overview"/>
            </div>
            <div id="clients-tab" class="tab-pane fade show" :class="tabs.clients ? ' active' : ''">
              <reports-clients v-if="tabs.clients"/>
            </div>
            <div id="ta-tab" class="tab-pane fade show" :class="tabs.ta ? ' active' : ''">
              <reports-ta v-if="tabs.ta"/>
            </div>
            <div id="hq-tab" class="tab-pane fade show" :class="tabs.hq ? ' active' : ''">
              <reports-hq v-if="tabs.hq"/>
            </div>
            <div id="msq-tab" class="tab-pane fade show" :class="tabs.msq ? ' active' : ''">
              <reports-msq v-if="tabs.msq"/>
            </div>
            <div id="orders-tab" class="tab-pane fade show" :class="tabs.orders ? ' active' : ''">
              <orders-reports v-if="tabs.orders" />
            </div>
            <div id="orders-tab" class="tab-pane fade show" :class="tabs.services ? ' active' : ''">
              <services-reports v-if="tabs.services" />
            </div>
            <div id="billing-tab" class="tab-pane fade show" :class="tabs.billing ? ' active' : ''">
              <billing-reports v-if="tabs.billing" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportsSidebar from "./Sidebar";
import ReportsOverview from "./partials/Overview";
import ReportsClients from "./partials/Clients";
import ReportsTa from "./partials/TermsAgreements";
import ReportsHq from "./partials/HealthQuestionaires";
import ReportsMsq from "./partials/MSQScoring";
import OrdersReports from "./partials/OrdersReport";
import ServicesReports from "./partials/Services";
import BillingReports from "./partials/BillingReports.vue";

export default {
  data() {
    return {
      tabs: {
        overview: true,
        clients: false,
        ta: false,
        hq: false,
        msq: false,
        orders: false,
        services: false,
        billing: false,
      }
    };
  },
  methods: {
    selectTab(tab) {
      this.clearTabs();
      switch(tab) {
        case "overview":
          this.tabs.overview = true;
        break;
        case "clients":
          this.tabs.clients = true;
        break;
        case "ta":
          this.tabs.ta = true;
        break;
        case "hq":
          this.tabs.hq = true;
        break;
        case "msq":
          this.tabs.msq = true;
        break;
        case "orders":
          this.tabs.orders = true;
        break;
        case "services":
          this.tabs.services = true;
        break;
        case "billing":
          this.tabs.billing = true;
        break;
      }
    },
    clearTabs() {
      this.tabs.overview = false;
      this.tabs.clients = false;
      this.tabs.ta = false;
      this.tabs.hq = false;
      this.tabs.msq = false;
      this.tabs.orders = false;
      this.tabs.services = false;
      this.tabs.billing = false;
    }
  },
  components: {
    ReportsSidebar,
    ReportsOverview,
    ReportsClients,
    ReportsTa,
    ReportsHq,
    ReportsMsq,
    OrdersReports,
    ServicesReports,
    BillingReports,
  },
};
</script>
