<template>
  <div class="card">
    <div class="card-header bg-primary text-white">
      <div class="row">
        <div class="col">
          <h5 class="p-2 fw-bold">Clients by Tag</h5>
        </div>
        <div class="col text-end">
          <DaterangePicker :dateRange.sync="dateRange" class="mt-1 text-dark" />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="btn btn-info btn-sm mx-1 mb-1"
            :class="filterTag === '' ? ' active' : ''"
            @click="filterTag = ''"
          >
            All
          </button>
          <button v-for="tag in getTags()" :key="tag"
            class="btn btn-info btn-sm mx-1 mb-1"
            :class="filterTag === tag ? ' active' : ''"
            @click="filterTag = tag"
          >
            {{tag}}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body" id="clientsreport">
      <busy :visible="!tagsClients" />
      <table
        v-if="tagsClients"
        class="table table-hover"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Became Client</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="c in getItems()"
            :key="c.id"
            @click="$parent.redirectToClient(c.id)"
            class="cursor-pointer"
          >
            <td>#{{ c.id }}</td>
            <td>{{ c.name }}</td>
            <td>{{ c.email }}</td>
            <td>{{ c.phone }}</td>
            <td>{{ c.became_client | formatDate }}</td>
            <td>
              <span
                v-for="tag in c.tags"
                :key="tag.id"
                class="badge bg-info ms-1 small"
              >
                {{ tag.name.en }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-3">
          <button
            class="btn btn-sm btn-light"
            @click="pagination.currentPage = 0"
            :disabled="pagination.currentPage === 0"
          >
            First Page
          </button>

          <button
            class="btn btn-sm btn-light"
            @click="pagination.currentPage -= 1"
            :disabled="pagination.currentPage === 0"
          >
            Previous
          </button>
        </div>
        <div class="col-6 text-center">
          <p class="mb-0">
            Page {{ pagination.currentPage }} of
            {{ pagination.pages }}
          </p>
        </div>
        <div class="col-3 text-end">
          <button
            class="btn btn-sm btn-light cursor-pointer me-3"
            @click="pagination.currentPage += 1"
            :disabled="pagination.currentPage === pagination.pages"
          >
            Next
          </button>

          <button
            class="btn btn-sm btn-light cursor-pointer"
            @click="pagination.currentPage = pagination.pages"
            :disabled="pagination.currentPage === pagination.pages"
          >
            Last Page
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
           <div class="text-center noprint pb-3">
                  <button
                    class="btn-lg btn btn-info me-3"
                    @click="printReport()"
                  >
                    <i class="fas fa-print"></i> Print
                  </button>
                  <download-csv
                    class="btn-lg btn btn-info"
                    :data="getItemsUnpaginated()"
                    :name="'Report_' + now + '.csv'"
                  >
                    <i class="fas fa-print"></i> Export
                  </download-csv>
                </div>
        </div>
        </div>
    </div>
  </div>
</template>
<script>
import DaterangePicker from "../DaterangePicker";
export default {
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },

      filterTag: "",
      clients: [],
      tagsClients: [],
      pagination: {
        show: 5,
        pages: 0,
        currentPage: 0,
        page_items: [],
      },

      loaded1: false,
    };
  },
  methods: {
    printReport() {
      this.$nextTick(() => {
        const element = window.document.getElementById("clientsreport");

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = window.open(
          "",
          "_blank",
          "toolbar=0,scrollbars=0,status=0",
          true
        );
        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Document Preview</title>
          </head>
          <body>
            ${element.innerHTML}
          </body>
        </html>
      `);
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          cb();
        }, 1000);
      });
    },
    getClients() {
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);
      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/clients?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.clients = data;
        });
    },
    getClientsTags() {
        return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/clients-tags"
        )
        .then(({ data }) => {
          this.tagsClients = data;
        });
    },
    getItemsUnpaginated() {
      if (this.tagsClients.length < this.pagination.show) {
        return this.tagsClients;
      }
      var items = this.tagsClients.slice(0);
      if(this.filterTag !== '') {
        items = items.filter((c) => {
          return c.tags.find((t) => t.name.en.toLowerCase() === this.filterTag.toLowerCase());
        })
      }
      return items;
    },
    getItems() {
      if (this.tagsClients.length < this.pagination.show) {
        return this.tagsClients;
      }
      var items = this.tagsClients.slice(0);
      if(this.filterTag !== '') {
        items = items.filter((c) => {
          return c.tags.find((t) => t.name.en.toLowerCase() === this.filterTag.toLowerCase());
        })
      }
      this.pagination.pages =
        Math.ceil(items.length / this.pagination.show) - 1;
      var paginatedClients = items.slice(
        this.pagination.currentPage * this.pagination.show,
        this.pagination.currentPage * this.pagination.show +
          this.pagination.show
      );
      return paginatedClients;
    },
    getTags() {
      let tags = [];
      this.tagsClients.forEach((c) => {
        c.tags.forEach((clientTag) => {
          if(!tags.find((t) => t === clientTag.name.en.toLowerCase())) {
            tags.push(clientTag.name.en.toLowerCase());
          }
        })
      });
      return tags;
    }
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(1, "year").startOf('day');
    this.dateRange.endDate = moment().startOf('day');
    this.getClients();
    this.getClientsTags();
  },
  watch: {
    dateRange: function () {
      this.getClients();
      this.getClientsTags();
    },
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("Do MMM YYYY") : "Not Set";
    },
  },
  computed: {
    now() {
      return moment().toDate();
    },
  },
  components: {
    DaterangePicker,
  },
};
</script>