<template>
  <div class="card">
    <div class="card-header bg-primary text-white">
      <div class="row">
        <div class="col">
          <h5 class="p-2 fw-bold">New Clients</h5>
        </div>
        <div class="col text-center">
          <DaterangePicker :dateRange.sync="dateRange" class="mt-1 text-dark" />
        </div>
      </div>
    </div>
    <div class="card-body">
      <busy :visible="!clients" />
      <table
        v-if="clients"
        class="table table-hover"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Became Client</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="c in getItems()"
            :key="c.id"
            @click="$parent.redirectToClient(c.id)"
            class="cursor-pointer"
          >
            <td>#{{ c.id }}</td>
            <td>{{ c.name }}</td>
            <td>{{ c.became_client | formatDate }}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-3">
          <button
            class="btn btn-sm btn-light"
            @click="pagination.currentPage = 0"
            :disabled="pagination.currentPage === 0"
          >
            First Page
          </button>

          <button
            class="btn btn-sm btn-light"
            @click="pagination.currentPage -= 1"
            :disabled="pagination.currentPage === 0"
          >
            Previous
          </button>
        </div>
        <div class="col-6 text-center">
          <p class="mb-0">
            Page {{ pagination.currentPage }} of
            {{ pagination.pages }}
          </p>
        </div>
        <div class="col-3 text-end">
          <button
            class="btn btn-sm btn-light cursor-pointer me-3"
            @click="pagination.currentPage += 1"
            :disabled="pagination.currentPage === pagination.pages"
          >
            Next
          </button>

          <button
            class="btn btn-sm btn-light cursor-pointer"
            @click="pagination.currentPage = pagination.pages"
            :disabled="pagination.currentPage === pagination.pages"
          >
            Last Page
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DaterangePicker from "../DaterangePicker";
export default {
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },

      clients: [],
      pagination: {
        show: 5,
        pages: 0,
        currentPage: 0,
        page_items: [],
      },
    };
  },
  methods: {
    getClients() {
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);
      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/clients?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.clients = data;
        });
    },
    getItems() {
      if (this.clients.length < this.pagination.show) {
        return this.clients;
      }
      this.pagination.pages = Math.ceil(
        this.clients.length / this.pagination.show
      ) - 1;
      var paginatedClients = this.clients.slice(
        this.pagination.currentPage * this.pagination.show,
        (this.pagination.currentPage * this.pagination.show) + this.pagination.show
      );
      return paginatedClients;
    },
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(1, "month").startOf('day');
    this.dateRange.endDate = moment().startOf('day');
    this.getClients();
  },
  watch: {
    dateRange: function () {
      this.getClients();
    },
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("Do MMM YYYY") : "Not Set";
    },
  },
  components: {
    DaterangePicker,
  },
};
</script>