<template>
  <div>
    <div class="row mb-4">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <i class="fas fa-hands-helping fa-3x text-primary"></i>
            </div>
            <h5
              class="text-muted font-weight-normal mt-0"
              title="Clinic Terms and Agreements"
            >
              Consultations
            </h5>
            <h5 class="mt-3 mb-3">
              <b>{{ consultations.length }}</b> booked
            </h5>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <i class="fas fa-hands-helping fa-3x text-primary"></i>
            </div>
            <h5
              class="text-muted font-weight-normal mt-0"
              title="Clinic Terms and Agreements"
            >
              Services
            </h5>
            <h5 class="mt-3 mb-3">
              <b>{{ services.length }}</b> available
            </h5>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <i class="fas fa-box-open fa-3x text-primary"></i>
            </div>
            <h5
              class="text-muted font-weight-normal mt-0"
              title="Clinic Terms and Agreements"
            >
              Packages
            </h5>
            <h5 class="mt-3 mb-3">
              <b>{{ packages.length }}</b> available
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
         <div class="card-header bg-primary text-white">
            <div class="row">
              <div class="col">
                <h5 class="p-2 fw-bold">Booked Services</h5>
              </div>
              <div class="col text-center">
                <DaterangePicker :dateRange.sync="dateRange" class="mt-1 text-dark"/>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  class="btn btn-light btn-sm mx-1"
                  @click="bookedServiceFilter = null"
                  :class="!bookedServiceFilter ? 'active' : ''"
                >
                  All
                </button>
                <button
                  class="btn btn-light btn-sm mx-1"
                  @click="bookedServiceFilter = 'unconfirmed'"
                  :class="
                    bookedServiceFilter === 'unconfirmed' ? 'active' : ''
                  "
                >
                  Unconfirmed
                </button>
                <button
                  class="btn btn-light btn-sm mx-1"
                  @click="bookedServiceFilter = 'confirmed'"
                  :class="
                    bookedServiceFilter === 'confirmed' ? 'active' : ''
                  "
                >
                  Confirmed
                </button>

                <div class="dropleft d-inline mx-1">
                  <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="sortByDropDown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Sort by
                  </button>
                  <div class="dropdown-menu" aria-labelledby="sortByDropDown">
                    <a
                      class="dropdown-item"
                      @click="bookedServiceOrder = 'oldest-newest'"
                      >Oldest to Newest</a
                    >
                    <a
                      class="dropdown-item"
                      @click="bookedServiceOrder = 'newest-oldest'"
                      >Newest to Oldest</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto my-2">
                <button class="btn btn-info btn-sm mx-1 mb-2"
                :class="serviceFilter === null ? 'active' : ''"
                @click="serviceFilter = null"
                > Any Service </button>
                <button
                  v-for="service in services"
                  :key="service.id"
                  @click="serviceFilter = service.name"
                  class="btn btn-info btn-sm mx-1 mb-2"
                  :class="serviceFilter === service ? 'active' : ''"
                >
                  {{ service.name }}
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
             <table class="table">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Service</th>
                  <th>Created</th>
                  <th>Start</th>
                  <th>Confirmed</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody v-if="filteredConsultations && filteredConsultations.length > 0">
                <tr v-for="c in filteredConsultations">
                  <td>{{ c.first_name + " " + c.last_name }}</td>
                  <td>{{ c.service }}</td>
                  <td>{{ c.created | formatDate }}</td>
                  <td>{{ c.start | formatDate }}</td>
                  <td><span v-if="c.confirmed"><i class="fas fa-check"></i></span></td>
                  <td>{{c.symbol}}{{ c.price | currencyFormat }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DaterangePicker from "./DaterangePicker";

export default {
  props: ["user"],
  data() {
    return {
      services: [],
      packages: [],
      activities: [],
      consultations: [],
      filteredConsultations: [],
      bookedServiceFilter: null,
      serviceFilter: null,
      bookedServiceOrder: null,

      dateRange: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    async fetchServiceEvents() {
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      this.$axios
        .get(process.env.VUE_APP_API_URL + "/reports/service-events?start=" +
            start +
            "&end=" +
            end)
        .then(({ data }) => {
          this.consultations = data.consultations;
          this.filteredConsultations = this.consultations.slice(0);
        });
    },
    async fetchServices() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/settings/services/api")
        .then(({ data }) => {
          this.services = data;
        });
    },
    async fetchPackages() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/settings/packages/api")
        .then(({ data }) => {
          this.packages = data;
        });
    },
    fetchConsultations() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/consultations/api/index").then(({ data }) => {
        this.consultations = data.data;
      });
    },
    filterItems() {
      this.filteredConsultations = this.consultations.slice(0);
      if(this.serviceFilter) {
        this.filteredConsultations = this.filteredConsultations.filter((item) => {
          return item.service == this.serviceFilter
        });
      }
      switch(this.bookedServiceFilter) {
        case 'unconfirmed':
          this.filteredConsultations = this.filteredConsultations.filter((item) => {
            return !item.confirmed;
          });
          break;
        case 'confirmed':
          this.filteredConsultations = this.filteredConsultations.filter((item) => {
            return item.confirmed;
          });
          break;
      }
      switch(this.bookedServiceOrder) {
        case 'oldest-newest':
          this.filteredConsultations = this.filteredConsultations.sort(function(a, b) {
            var keyA = new Date(a.created),
            keyB = new Date(b.created);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
          });
          break;
        case 'newest-oldest':
          this.filteredConsultations = this.filteredConsultations.sort(function(b, a) {
            var keyA = new Date(a.created),
            keyB = new Date(b.created);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
          });
          break;
      }
    }
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(1, "month").startOf('day');
    this.dateRange.endDate = moment().startOf('day');

    this.fetchServiceEvents();
    this.fetchServices();
    this.fetchPackages();
  },
  filters: {
    date(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      return Intl.DateTimeFormat("en-US", options).format(value);
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("Do MMM YYYY");
      } else {
        return null;
      }
    },
    currencyFormat(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    },
  },
  watch: {
    dateRange: function () {
      this.fetchServiceEvents();
    },
    bookedServiceFilter: function() {
      this.filterItems();
    },
    bookedServiceOrder: function() {
      this.filterItems();
    },
    serviceFilter: function() {
      this.filterItems();
    }
  },
  components: {
    DaterangePicker,
  },
};
</script>
