<template>
  <div class="card border-0 shadow-sm mb-3 sticky-top">
    <div class="card-header mb-2 pb-0">
      <div class="row mb">
        <div class="col">
          <span><h1 class="text-primary h4 mb-1 d-inline">Reports</h1></span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="scollable-nav">
        <div class="nav flex-column nav-pills my-3">
          <a
            @click="$parent.selectTab('overview')"
            class="nav-link cursor-pointer"
            :class="tabs.overview ? ' active' : ''"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="fas fa-tachometer-slowest"></i>
              </div>
              <div class="col my-auto">Overview</div>
            </div>
          </a>
          <a
            @click="$parent.selectTab('clients')"
            class="nav-link cursor-pointer"
            :class="tabs.clients ? ' active' : ''"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="fas fa-user-friends"></i>
              </div>
              <div class="col my-auto">Clients</div>
            </div>
          </a>
          <template v-if="tabs.clients">
          <a
            @click="$parent.selectTab('clients')"
            href="#newclients"
            class="nav-link cursor-pointer"
          > <div class="row">
              <div class="ms-3 col-2 text-center my-auto">
                <i class="fas fa-minus"></i>
              </div>
              <div class="col my-auto">New Clients</div>
            </div>
          </a>
           <a
            @click="$parent.selectTab('clients')"
            href="#reports"
            class="nav-link cursor-pointer"
          >
            <div class="row">
              <div class="ms-3 col-2 text-center my-auto">
                <i class="fas fa-minus"></i>
              </div>
              <div class="col my-auto">Client Reports</div>
            </div>
          </a>
          </template>
          <a
            @click="$parent.selectTab('orders')"
            class="nav-link cursor-pointer"
            :class="tabs.orders ? ' active' : ''"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="far fa-clipboard-list"></i>
              </div>
              <div class="col my-auto">Orders</div>
            </div>
          </a>
          <a
            @click="$parent.selectTab('services')"
            class="nav-link cursor-pointer"
            :class="tabs.services ? ' active' : ''"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="far fa-box-full"></i>
              </div>
              <div class="col my-auto">Services</div>
            </div>
          </a>
          <a
            @click="$parent.selectTab('ta')"
            class="nav-link cursor-pointer"
            :class="tabs.ta ? ' active' : ''"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="fas fa-file-signature"></i>
              </div>
              <div class="col my-auto">Terms & Agreements</div>
            </div>
          </a>
          <a
            @click="$parent.selectTab('hq')"
            class="nav-link cursor-pointer"
            :class="tabs.hq ? ' active' : ''"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="far fa-scroll"></i>
              </div>
              <div class="col my-auto">Health Questionaires</div>
            </div>
          </a>
          <a
            @click="$parent.selectTab('msq')"
            class="nav-link cursor-pointer"
            :class="tabs.msq ? ' active' : ''"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="far fa-file-chart-line"></i>
              </div>
              <div class="col my-auto">MSQ Reports</div>
            </div>
          </a>
          <a
            @click="$parent.selectTab('billing')"
            class="nav-link cursor-pointer"
            :class="tabs.billing ? ' active' : ''"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="far fa-file-chart-line"></i>
              </div>
              <div class="col my-auto">Billing Reports</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['tabs']
};
</script>
<style scoped>
.nav-link:hover {
  background-color: #ddd;
}
</style>