<template>
  <div>
    <busy :visible="!ready" />
    <div v-if="ready">
      <div class="row mb-3"  id="newclients">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <LineChartQtyMonth
                v-if="yearData"
                name="New monthly clients"
                :start="yearRange.startDate"
                :end="yearRange.endDate"
                :data="yearData"
              />
              <busy :visible="!yearData" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-8 col-md-12">
          <new-clients />
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="card">
            <div class="card-header bg-primary text-white">
              <h5 class="p-2 fw-bold">Upcoming Birthdays</h5>
            </div>

          <div class="card-body">
              <table class="table">
                  <tbody>
                      <tr v-for="c in dobClients" :key="c.id">
                          <td>{{c.name}}</td>
                          <td>{{c.dob | formatDOB}}</td>
                    </tr>
                  </tbody>
              </table>
          </div>
          </div>
        </div>
      </div>
      <div class="row mb-3" id="reports">
        <div class="col">
          <client-reports />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import LineChartQtyMonth from "./charts/LineTimeQtyMonth";
import NewClients from "./client-partials/newclients";
import ClientReports from "./client-partials/clientreports";

export default {
  data() {
    return {
      yearRange: {
        startDate: null,
        endDate: null,
      },

      yearClients: [],
      yearData: [],

      clients: [],
      dobClients: [],

      ready: false,
    };
  },
  methods: {
    getYearData() {
      for (let i = 0; i < 12; i++) {
        this.yearData[i] = [];
        let month = new Date(this.yearRange.startDate);
        month.setMonth(month.getMonth() + i);
        let mkey =
          new Date(month).getMonth() + " " + new Date(month).getFullYear();

        let clients = this.yearClients.filter((client) => {
          let key =
            new Date(client.created_at).getMonth() +
            " " +
            new Date(client.created_at).getFullYear();
          return mkey === key;
        });

        this.yearData[i] = { x: month, y: clients.length };
      }

      console.log(this.yearData);
    },
    getClientsLastYear() {
      var start = moment(this.yearRange.startDate);
      var end = moment(this.yearRange.endDate);

      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/clients?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.yearClients = data;
          this.getYearData();
        });
    },
    getDOBClients() {
        return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/clients/dob?days=14"
        )
        .then(({ data }) => {
          this.dobClients = data;
        });
    },
    redirectToClient(id) {
      this.$router.push("/clients/" + id);
    },
  },

  mounted() {
    this.getDOBClients();
    this.yearRange.startDate = moment().subtract(1, "year").startOf('day');
    this.yearRange.endDate = moment().startOf('day');
    this.getClientsLastYear().then(() => {
      this.ready = true;
    });
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("Do MMM YYYY") : "Not Set";
    },
    formatDOB(date) {
      return date ? moment(date).format("Do MMM") : "Not Set";
    },
  },
  computed: {
    now() {
      return moment().toDate();
    },
  },
  components: {
    Multiselect,
    LineChartQtyMonth,
    NewClients,
    ClientReports
  },
};
</script>
<style scoped>
html {
    scroll-behavior: smooth !important;
}
</style>