<script>
import { Line, mixins } from "vue-chartjs";
export default {
  extends: Line,
  props: ["data", "labels"],
  data() {
    return {};
  },
  methods: {
    updateChart() {
      this.renderChart(
        {
          labels: this.labels,
          datasets: this.createdDatasets(),
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  format: "DD/MM/YYYY",
                  tooltipFormat: "ll",
                },
                scaleLabel: {
                  display: true,
                  labelString: "Date",
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "score",
                },
              },
            ],
          },
        }
      );
    },
    createdDatasets() {
      let dataset = [];
      console.log("update", this.data);
      this.data.forEach((item, index) => {
        let color = this.getRandomColor();
        dataset.push({
          label: item[0],
          backgroundColor: color,
          fill: false,
          data: item[1],
          borderColor: color,
        });
      });
      console.log("done");
      return dataset;
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF".split("");
      let color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  mounted() {
    this.updateChart();
  },
  filters: {},
  watch: {},
  computed: {
    highestDataPoint() {
      if (this.data.length == 0) {
        return;
      }
      return this.data.reduce((a, b) => (Number(a) > Number(b) ? a : b));
    },
  },
  components: {
    Line,
  },
};
</script>

<style>
</style>
