<template>
  <div>
    <div class="row mb-4 mt-2">
      <div class="col">
        <DaterangePicker :dateRange.sync="dateRange" />
      </div>
    </div>
    <busy :visible="!ready" />
    <div v-if="ready">
      <div class="row mb-3">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header bg-primary text-white">
              <div class="row">
                <div class="col-auto my-auto">
                  <h5 class="p-2 fw-bold">Monthly Billing Totals</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <table class="table" v-if="billingTotals">
                <tr>
                  <th>Practitioner</th>
                  <th>Month</th>
                  <th>Total</th>
                </tr>
                <tr v-for="bTotal in billingTotals">
                  <td>{{ bTotal.first_name }} {{ bTotal.last_name }}</td>
                  <td>
                    {{ ("0" + bTotal.month).slice(-2) }}/{{ bTotal.year }}
                  </td>
                  <td>£{{ bTotal.total_billed.toFixed(2) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DaterangePicker from "./DaterangePicker";

export default {
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      ready: false,
      billingTotals: null,
    };
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(1, "year").startOf("day");
    this.dateRange.endDate = moment().startOf("day");

    this.$axios
      .get(
        process.env.VUE_APP_API_URL +
          "/reports/billing-totals?start=" +
          this.dateRange.startDate +
          "&end=" +
          this.dateRange.endDate
      )
      .then(({ data }) => {
        this.billingTotals = data;
        this.ready = true;
      });
  },
  components: {
    DaterangePicker,
  },
};
</script>