<template>
  <div>
    <date-range-picker
      ref="picker"
      :opens="dateRangeConfig.opens"
      :ranges="dateRangeConfig.ranges"
      :show-dropdowns="dateRangeConfig.showDropdowns"
      :locale-data="dateRangeConfig.locale"
      v-model="ldateRange"
    >
      <div slot="input" slot-scope="picker" style="min-width: 350px" class="text-center">
        {{ picker.startDate | date }} - {{ picker.endDate | date }}
      </div>
    </date-range-picker>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  props: ["dateRange"],
  components: {
    DateRangePicker,
  },
  data() {
    return {
      ldateRange: {
        startDate: null,
        endDate: null,
      },
      dateRangeConfig: {
        opens: "center",
        showDropdowns: false,
        locale: {
          direction: "ltr", //direction of text
          format: "DD-MM-YYYY", //fomart of the dates displayed
          separator: " - ", //separator between the two ranges
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          customRangeLabel: "Custom Range",
          daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
          monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
          firstDay: 1, //ISO first day of week - see moment documenations for details
        },
        ranges: {
          //Today: [moment().toDate(), moment().toDate()],
          Yesterday: [
            moment().subtract(1, "days").startOf('day').toDate(),
            moment().subtract(1, "days").endOf('day').toDate(),
          ],
          "Last week": [
            moment().subtract(1, "week").startOf("week").toDate(),
            moment().subtract(1, "week").endOf("week").toDate(),
          ],
          "This month": [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
          ],
          "Last month": [
            moment().subtract(1, "month").startOf("month").toDate(),
            moment().subtract(1, "month").endOf("month").toDate(),
          ],
          "This year": [
            moment().startOf("year").toDate(),
            moment().endOf("year").toDate(),
          ],
        },
      },
    };
  },
  filters: {
    date(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      return Intl.DateTimeFormat("en-US", options).format(value);
    },
  },
  mounted() {
    this.ldateRange = this.dateRange;
  },
  watch: {
    ldateRange: function () {
      this.$emit("update:dateRange", this.ldateRange);
    },
  },
};
</script>