<template>
  <div>
    <busy :visible="!ready" />
    <div v-if="ready" class="row mb-4 mt-2" >
      <div class="col">
        <DaterangePicker :dateRange.sync="dateRange" />
      </div>
    </div>
    <div class="row mb-3" v-if="ready">
      <div class="col-lg-4 col-md-6">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-auto my-auto">
                <h5 class="p-2 fw-bold">Order Types</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <doughnut-types
              :data="typesChartData"
              :options="typesChartOptions"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-auto my-auto">
                <h5 class="p-2 fw-bold">Daily Orders</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <line-time-qty
              :name="'Total Orders'"
              :start="dateRange.startDate"
              :end="dateRange.endDate"
              :data="groupedOrderData"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-auto my-auto">
                <h5 class="p-2 fw-bold">Order Data</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table">
              <tr>
                <th>ID</th>
                <th>Practitioner</th>
                <th>Status</th>
                <th>Type</th>
                <th>Description</th>
                <th>Created</th>
              </tr>
              <tr v-for="order in $stash.reportData.orders" :key="order.id">
                <td>{{ order.id }}</td>
                <td>{{ order.firstName }} {{ order.lastName }}</td>
                <td>{{ order.status | formatUnderScored }}</td>
                <td>{{ order.type | formatUnderScored }}</td>
                <td>{{ order.title}}</td>
                <td>{{ order.created_at | formatDateTime }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DaterangePicker from "./DaterangePicker";
import DoughnutTypes from "./charts/DoughnutTypes";
import LineTimeQty from "./charts/LineTimeQty.vue";

export default {
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },

      ready: false,
      Standard: 0,
      Consultation: 0,
      Custom: 0,
      OnlineProgramme: 0,
      Historic: 0,

      groupedOrderData: null,

      typesChartOptions: {
        hoverBorderWidth: 20,
      },
      typesChartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [
          "Standard",
          "Consultation",
          "Custom",
          "Online Programme",
          "Historic",
        ],
        datasets: [
          {
            label: "Type",
            backgroundColor: [
              "#41B883",
              "#E46651",
              "#00D8FF",
              "#00AAAA",
              "#F0F80F",
            ],
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    getOrders() {
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/orders?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.$stash.reportData.orders = data;
        });
    },
    chartDataFromOrders() {
      for (let i = 0; i < this.$stash.reportData.orders.length; i++) {
        switch (this.$stash.reportData.orders[i].type) {
          case "standard":
            this.Standard++;
            break;
          case "consultation":
            this.Consultation++;
            break;
          case "custom":
            this.Custom++;
            break;
          case "online programme":
            this.OnlineProgramme++;
            break;
          case "historic":
            this.Historic++;
            break;
        }
      }
      this.typesChartData.datasets[0].data = [
        this.Standard,
        this.Consultation,
        this.Custom,
        this.OnlineProgramme,
        this.Historic,
      ];

      this.groupedOrderData = _.groupBy(
        this.$stash.reportData.orders,
        function (item) {
          return moment(item.created_at).startOf("day").format();
        }
      );

      let datacount = [];
      let keys = Object.keys(this.groupedOrderData);
      for (let i = 0; i < keys.length; i++) {
        let date = new Date(keys[i]);
        let formatDate =
          ("0" + date.getDate()).slice(-2) +
          "/" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "/" +
          date.getFullYear();
        datacount.push({
          x: formatDate,
          y: this.groupedOrderData[keys[i]].length,
        });
      }

      this.groupedOrderData = datacount;
    },
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(1, "month").startOf("day");
    this.dateRange.endDate = moment().startOf("day");
    this.getOrders().then(() => {
      this.chartDataFromOrders();
      this.ready = true;
    });
  },

  computed: {
    now() {
      return moment().toDate();
    },
  },
  watch: {
    dateRange: function () {
      this.ready = false;
      this.getOrders().then(() => {
        this.chartDataFromOrders();
        this.ready = true;
      });
    },
  },
  components: {
    Multiselect,
    DaterangePicker,
    DoughnutTypes,
    LineTimeQty,
  },
  filters: {
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatUnderScored(text) {
      if (text != null) {
        return text.replace(/_/g, " ");
      }
      return "-";
    }
  }
};
</script>
<style scoped>
</style>