<script>
import { Line } from "vue-chartjs";
export default {
  props: ["name", "start", "end", "data"],
  extends: Line,
  data() {
    return {
      datacollection: {
        datasets: [
          {
            label: this.name,
            backgroundColor: "#413dc2",
            data: this.data,
            fill: true,
            //steppedLine: true,
          },
        ],
      },
      options: {
        scales: {
           yAxes: [{
                stacked: true
            }],
          xAxes: [
            {
              type: "time",
              time: {
                unit: 'month',
                displayFormats: { month: 'MMM YYYY' },
              },
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.datacollection, this.options);
  },
};
</script>