<template>
  <div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="alert alert-dark" role="alert">
          Please be aware that some report data is updated daily and today's changes may not be displayed.
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-2">
        <div class="card border-0 bg-white text-primary shadow-sm">
          <a
            @click="$parent.selectTab('clients')"
            class="stretched-link cursor-pointer"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="fas fa-user-friends fa-3x"></i>
              </div>
              <h5
                title="Clinic Terms and Agreements"
                class="text-muted font-weight-normal mt-0"
              >
                Clients
              </h5>
              <busy :visible="!clientsReady" />
              <h3 class="mt-3 mb-3" v-if="clientsReady">
                <b>{{ newClients ? newClients : 0 }}</b> New
              </h3>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mb-2">
        <div class="card border-0 bg-white text-primary shadow-sm">
          <a
            @click="$parent.selectTab('msq')"
            class="stretched-link cursor-pointer"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="far fa-file-chart-line fa-3x"></i>
              </div>
              <h5
                title="Clinic Terms and Agreements"
                class="text-muted font-weight-normal mt-0"
              >
                MSQ Forms
              </h5>
              <busy :visible="!formsReady" />
              <h3 class="mt-3 mb-3" v-if="formsReady">
                <b>{{ scoredItems().length }}</b> Scored
              </h3>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mb-2">
        <div class="card border-0 bg-white text-primary shadow-sm">
          <a
            @click="$parent.selectTab('orders')"
            class="stretched-link cursor-pointer"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="far fa-clipboard-list fa-3x"></i>
              </div>
              <h5
                title="Clinic Terms and Agreements"
                class="text-muted font-weight-normal mt-0"
              >
                Orders
              </h5>
              <busy :visible="!ordersReady" />
              <h3 class="mt-3 mb-3" v-if="ordersReady">
                <b>{{ newOrders ? newOrders : 0 }}</b> Created
              </h3>
            </div>
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mb-2">
        <div class="card border-0 bg-white text-primary shadow-sm">
          <a
            @click="$parent.selectTab('orders')"
            class="stretched-link cursor-pointer"
          >
            <div class="card-body">
              <div class="float-end">
                <i class="far fa-calendar-check fa-3x"></i>
              </div>
              <h5
                title="Clinic Terms and Agreements"
                class="text-muted font-weight-normal mt-0"
              >
                Consultations
              </h5>
              <busy :visible="!consultationsReady" />
              <h3 class="mt-3 mb-3" v-if="consultationsReady">
                <b>{{ newConsultations ? newConsultations : 0 }}</b> Booked
              </h3>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-3 col-md-6">
        <div class="card border-0 bg-white text-primary shadow-sm">
          <a
            @click="$parent.selectTab('ta')"
            class="stretched-link cursor-pointer"
          >
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-8 mx-auto text-center">
                <i class="far fa-handshake fa-4x"></i>
              </div>
            </div>
            <busy :visible="!agreementsReady" />
            <div class="row" v-if="agreementsReady">
              <div class="col text-center">
                <h5 class="mb-0 fw-bold">Clinic Terms</h5>
                <p class="mb-0 bg-light mt-3 p-2 text-primary">
                  <b>{{ this.$stash.reportData.allAgreements.length }}</b> sent
                </p>
                <p class="mb-3 bg-light mt-0 p-2 text-primary">
                  <b>{{ this.numOutstandingAgreements }}</b> outstanding
                </p>
                <div class="progress" style="border: 1px solid #ffffff">
                  <div
                    class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                    role="progressbar"
                    v-bind:style="
                      'width:' + this.agreementsCompletedPercentage + '%'
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
          </a>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="card border-0 bg-white text-primary shadow-sm">
          <a
            @click="$parent.selectTab('ta')"
            class="stretched-link cursor-pointer"
          >
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-8 mx-auto text-center">
                <i class="far fa-align-center fa-4x"></i>
              </div>
            </div>
            <busy :visible="!formsReady" />
            <div v-if="formsReady" class="row">
              <div class="col text-center">
                <h5 class="mb-0 fw-bold">Health Questionnaires</h5>
                <p class="mb-0 bg-light mt-3 p-2 text-primary">
                  <b>{{ this.$stash.reportData.allForms.length }}</b> sent
                </p>
                <p class="mb-3 bg-light mt-0 p-2 text-primary">
                  <b>{{
                    this.numOutstandingForms > 0
                      ? this.numOutstandingForms
                      : "0"
                  }}</b>
                  outstanding
                </p>
                <div class="progress" style="border: 1px solid #ffffff">
                  <div
                    class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                    role="progressbar"
                    v-bind:style="
                      'width:' + this.formsCompletedPercentage + '%'
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DaterangePicker from "./DaterangePicker";

export default {
  props: ["user"],
  data() {
    return {
      currentForms: [],
      formTypes: [],
      numOutstandingForms: 0,
      currentFormTypeFilter: null,
      currentFormStatusFilter: null,
      formsCompletedPercentage: 0,

      currentAgreements: [],
      agreementTypes: [],
      numOutstandingAgreements: 0,
      currentAgreementTypeFilter: null,
      currentAgreementStatusFilter: null,
      agreementsCompletedPercentage: 0,

      consultations: [],


      newOrders: 0,
      newClients: 0,
      newConsultations: 0,

      dateRange: {
        startDate: null,
        endDate: null,
      },

      formsReady: false,
      clientsReady: false,
      agreementsReady: false,
      ordersReady: false,
      consultationsReady: false,
    };
  },
  methods: {
    async fetchConsultations() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/consultations/api/index").then(({ data }) => {
        this.consultations = data.data;
        this.newConsultations = this.consultations.length;
      });
    },
    loadData() {
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/overview?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.$stash.reportData.allForms = data.forms;
          this.$stash.reportData.allAgreements = data.terms;
        });
    },
    goToClientForms(clientId) {
      //redirect to client forms section
      this.$router.push("clients/" + clientId + "#forms");
    },

    async loadOrdersData() {
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/orders?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.$stash.reportData.orders = data;
          this.newOrders = this.$stash.reportData.orders.length;
        });
    },

    async loadClientsData() {
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/clients?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.$stash.reportData.clients = data;
          this.newClients = this.$stash.reportData.clients.length;
        });
    },

    reSendRequest(r) {
      var confirmed = confirm(
        "Are you sure you wish to re-send this form request?"
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              r.client_id +
              "/resend-request",
            {
              form_template_id: r.form_template_id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.loadFormData();
          });
      }
    },
    scoredItems() {
      let forms = this.currentForms;
      let scoredForms = forms.filter(function (f) {
        return f.score > 0;
      });

      return scoredForms;
    },
    calculateNumOutstandingAgreements() {
      //iterate Agreements to determine num outstanding used in calculations
      var numOutstanding = 0;

      this.currentAgreements.forEach((item) => {
        if (!item.accepted) {
          numOutstanding++;
        }
      });

      this.numOutstandingAgreements = numOutstanding;
      return numOutstanding;
    },
    calculateNumOutstandingForms() {
      //iterate forms to determine num outstanding used in calculations
      var numOutstanding = 0;

      this.currentForms.forEach((item) => {
        if (!item.client_response_date) {
          numOutstanding++;
        }
      });

      this.numOutstandingForms = numOutstanding;
    },
  },
  watch: {
    dateRange: function () {
      this.loadData();
      this.loadOrdersData();
      this.loadClientsData();
    },
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(1, "month").startOf('day');
    this.dateRange.endDate = moment().startOf('day');

    this.loadData().then(() => {
      this.currentForms = this.$stash.reportData.allForms;
      this.calculateNumOutstandingForms();
      this.formsReady = true;
      this.currentAgreements = this.$stash.reportData.allAgreements;
      this.calculateNumOutstandingAgreements();
      this.agreementsReady = true;
    });

    this.loadOrdersData().then(() => (this.ordersReady = true));
    this.loadClientsData().then(() => (this.clientsReady = true));
    this.fetchConsultations().then(() => (this.consultationsReady = true));
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("Do MMM YYYY");
      } else {
        return null;
      }
    },
    formatUnderScored(text) {
      if (text != null) {
        return text.replace(/_/g, " ");
      }
      return "-";
    },
  },
  components: {
    DaterangePicker,
  },
};
</script>