<template>
  <div>
    <div class="row mb-4 mt-2">
      <div class="col-auto my-auto">
        <DaterangePicker :dateRange.sync="dateRange" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <i class="far fa-handshake fa-4x text-primary"></i>
            </div>
            <a class="stretched-link cursor-pointer" @click="showAllAgreements">
            <h5
              class="text-muted font-weight-normal mt-0"
              title="Clinic Terms and Agreements"
            >
              Clinic Terms
            </h5>
            <h3 class="mt-3 mb-3">
              <b>{{ this.allAgreements.length }}</b> sent
            </h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="float-end">
              <i class="far fa-times text-danger fa-4x"></i>
            </div>
            <a class="stretched-link cursor-pointer" @click="showPendingAgreements">
            <h5
              class="text-muted font-weight-normal mt-0"
              title="Clinic Terms and Agreements"
            >
              Clinic Terms
            </h5>
            <h3 class="mt-3 mb-3">
              <b>{{ this.numOutstandingAgreements }}</b> outstanding
            </h3>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6">

      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-primary text-white">
            <div class="row mb-2">
              <div class="col-auto my-auto">
                <h5 class="p-2 fw-bold">
                  Clinic Terms & Agreements
                  <small>({{ allAgreements.length }})</small>
                </h5>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <button
                  v-for="agreementType in agreementTypes"
                  @click="filterAgreementsByType(agreementType)"
                  :ref="'form-type-' + agreementType.replace(/\s+/g, '')"
                  class="btn btn-info btn-sm mx-1"
                  :class="
                    currentAgreementTypeFilter === agreementType ? 'active' : ''
                  "
                >
                  {{ agreementType }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  class="btn btn-light btn-sm mx-1"
                  @click="showAllAgreements"
                  :class="!currentAgreementStatusFilter ? 'active' : ''"
                >
                  All
                </button>
                <button
                  class="btn btn-light btn-sm mx-1"
                  @click="showPendingAgreements"
                  :class="
                    currentAgreementStatusFilter === 'pending' ? 'active' : ''
                  "
                >
                  Pending Response
                </button>
                <button
                  class="btn btn-light btn-sm mx-1"
                  @click="showRespondedAgreements"
                  :class="
                    currentAgreementStatusFilter === 'responded' ? 'active' : ''
                  "
                >
                  Client Responded
                </button>

                <div class="dropleft d-inline mx-1">
                  <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="sortByDropDown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Sort by
                  </button>
                  <div class="dropdown-menu" aria-labelledby="sortByDropDown">
                    <a
                      class="dropdown-item"
                      @click="sortAgreementsBy('oldest-newest')"
                      >Oldest to Newest</a
                    >
                    <a
                      class="dropdown-item"
                      @click="sortAgreementsBy('newset-oldest')"
                      >Newest to Oldest</a
                    >
                  </div>
                </div>

                <button
                  class="btn btn-light btn-sm ms-1"
                  @click="showAllAgreements"
                >
                  <i class="far fa-times"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="card-body bg-white">
            <table class="table" v-if="allAgreements.length > 0">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Client</th>
                  <th scope="col">Agreement</th>
                  <th scope="col" class="text-center">Date Sent</th>
                  <th scope="col" class="text-center">Client Responded</th>
                  <th scope="col" class="text-center">Date Responded</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="agreement in currentAgreements"
                  :key="agreement.id"
                  class="cursor-pointer"
                >
                  <td>
                    <span class="badge badge-pill bg-info me-2">{{
                      agreement.id
                    }}</span>
                  </td>
                  <td>{{ agreement.client.name }}</td>
                  <td>{{ agreement.term.title }}</td>
                  <td class="text-center">
                    {{ agreement.created_at | formatDate }}
                  </td>
                  <td v-if="agreement.client_responded" class="text-center">
                    <i class="far fa-check text-success"></i>
                  </td>
                  <td v-else class="text-center">
                    <i class="far fa-times text-danger"></i>
                  </td>
                  <td class="text-center" v-if="agreement.client_responded">
                    {{ agreement.client_responded_date | formatDate }}
                  </td>
                  <td class="text-center" v-else>
                    <button
                      class="btn btn-sm btn-light ms-"
                      @click.stop="reSendAgreement(agreement)"
                    >
                      <i class="far fa-sync"></i>
                      Re-send
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              class="row my-4 text-center"
              v-if="currentAgreements.length == 0"
            >
              <div class="col">
                <h5>There are no Clinic Terms using that filter</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DaterangePicker from "./DaterangePicker";

export default {
  props: ["user"],
  data() {
    return {
      allAgreements: [],
      currentAgreements: [],
      agreementTypes: [],
      numOutstandingAgreements: 0,
      currentAgreementTypeFilter: null,
      currentAgreementStatusFilter: null,
      agreementsCompletedPercentage: 0,

      //date picker config
      dateRange: {
        startDate: null,
        endDate: null,
      },
      dateRangeConfig: {
        opens: "center",
        showDropdowns: false,
        locale: {
          direction: "ltr", //direction of text
          format: "DD-MM-YYYY", //fomart of the dates displayed
          separator: " - ", //separator between the two ranges
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          customRangeLabel: "Custom Range",
          daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
          monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
          firstDay: 1, //ISO first day of week - see moment documenations for details
        },
        ranges: {
          Today: [moment().toDate(), moment().toDate()],
          Yesterday: [
            moment().subtract(1, "days").toDate(),
            moment().subtract(1, "days").toDate(),
          ],
          "Last week": [
            moment().subtract(1, "week").startOf("week").toDate(),
            moment().subtract(1, "week").endOf("week").toDate(),
          ],
          "This month": [
            moment().startOf("month").toDate(),
            moment().endOf("month").toDate(),
          ],
          "Last month": [
            moment().subtract(1, "month").startOf("month").toDate(),
            moment().subtract(1, "month").endOf("month").toDate(),
          ],
          "This year": [
            moment().startOf("year").toDate(),
            moment().endOf("year").toDate(),
          ],
        },
      },
    };
  },
  methods: {
    openPicker(id) {
      var datePicker = document.querySelector(id);
      datePicker.click();
    },
    //Agreements
    showAllAgreements() {
      this.currentAgreementStatusFilter = null;
      this.applyAgreementFilters();
    },
    showPendingAgreements() {
      this.currentAgreementStatusFilter = "pending";
      this.applyAgreementFilters();
    },
    showRespondedAgreements() {
      this.currentAgreementStatusFilter = "responded";
      this.applyAgreementFilters();
    },
    filterAgreementsByType(type) {
      if (type) {
        if (this.currentAgreementTypeFilter === type) {
          this.currentAgreementTypeFilter = null;
        } else {
          this.currentAgreementTypeFilter = type;
        }
        this.applyAgreementFilters();
      }
    },
    applyAgreementFilters() {
      let temp = [];

      this.allAgreements.forEach((item) => {
        if (
          this.currentAgreementTypeFilter &&
          this.currentAgreementStatusFilter
        ) {
          //filter by both Agreement type and Agreement status
          if (this.currentAgreementStatusFilter === "pending") {
            if (
              item.term.title === this.currentAgreementTypeFilter &&
              !item.client_responded
            ) {
              temp.push(item);
            }
          } else if (this.currentAgreementStatusFilter === "responded") {
            if (
              item.term.title === this.currentAgreementTypeFilter &&
              item.client_responded
            ) {
              temp.push(item);
            }
          }
        } else if (this.currentAgreementTypeFilter) {
          //filter by Agreement type
          if (item.term.title === this.currentAgreementTypeFilter) {
            temp.push(item);
          }
        } else if (this.currentAgreementStatusFilter) {
          //filter by Agreement status
          if (this.currentAgreementStatusFilter === "pending") {
            if (!item.client_responded) {
              temp.push(item);
            }
          } else if (this.currentAgreementStatusFilter === "responded") {
            if (item.client_responded) {
              temp.push(item);
            }
          }
        } else {
          // no filters
          temp.push(item);
        }
      });
      this.currentAgreements = temp;
    },
    sortAgreementsBy(sort) {
      //sort current list of Agreements
      if (sort == "newset-oldest") {
        this.currentAgreements.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "oldest-newest") {
        this.currentAgreements.sort(
          (b, a) => new Date(a.created_at) - new Date(b.created_at)
        );
      }
    },
    gatherAgreementTypes() {
      //collect all distinct Agreement types
      //used to dynamically build the Agreement type filter list
      this.agreementTypes = [];
      for (var i = 0; i < this.allAgreements.length; i++) {
        if (
          this.agreementTypes.indexOf(this.allAgreements[i].term.title) === -1
        ) {
          this.agreementTypes.push(this.allAgreements[i].term.title);
        }
      }
    },
    calculateNumOutstandingAgreements() {
      //iterate Agreements to determine num outstanding used in calculations
      var numOutstanding = 0;

      this.allAgreements.forEach((item) => {
        if (!item.accepted) {
          numOutstanding++;
        }
      });

      this.numOutstandingAgreements = numOutstanding;
      return numOutstanding;
    },
    calculateAgreementCompletedPercentage() {
      //calculate percentage of Agreements that have been completed
      if (!this.numOutstandingAgreements) {
        this.numOutstandingAgreements = this.calculateNumOutstandingAgreements();
      }
      return (
        100 - (this.numOutstandingAgreements / this.allAgreements.length) * 100
      );
    },
    loadAgreementData() {
      //load Agreement data json from backend for given date range
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/agreements?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.allAgreements = data;
          this.calculateNumOutstandingAgreements();
          this.agreementsCompletedPercentage = this.calculateAgreementCompletedPercentage();
          this.gatherAgreementTypes();
          this.applyAgreementFilters();
        });
    },

    reSendAgreement(r) {
      var confirmed = confirm(
        "Are you sure you wish to re-send this agreement?"
      );
      if (confirmed) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              r.client.id +
              "/reshare-terms",
            {
              id: r.id,
              clinic_term_id: r.clinic_term_id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.loadAgreementData();
          });
      }
    },
  },
  watch: {
    dateRange: function (newDate, oldDate) {
      this.loadAgreementData();
    },
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(1, "month").startOf('day');;
    this.dateRange.endDate = moment().startOf('day');;

    this.loadAgreementData().then(() => {
      this.currentAgreements = this.allAgreements;
    });
  },
  filters: {
    date(value) {
      let options = { year: "numeric", month: "long", day: "numeric" };
      return Intl.DateTimeFormat("en-US", options).format(value);
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("Do MMM YYYY");
      } else {
        return null;
      }
    },
    formatUnderScored(text) {
      if (text != null) {
        return text.replace(/_/g, " ");
      }
      return "-";
    },
  },
  components: {
    DaterangePicker,
  },
};
</script>
