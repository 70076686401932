<template>
  <div>
    <div class="row mb-4 mt-2">
      <div class="col">
        <DaterangePicker :dateRange.sync="dateRange" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-primary text-white">
            <div class="row">
              <div class="col-auto my-auto">
                <h5 class="p-2 fw-bold">MSQ Reporting</h5>
              </div>
            </div>
            <div class="row mb-2 p-2">
              <div class="col-md-6">
                <div class="form-inline justify-content-center">
                  <label class="me-2 mt-2">Select Clients: </label>
                  <multiselect
                    v-model="chartSelectedClients"
                    :options="scoredClients()"
                    :multiple="true"
                    label="name"
                    track-by="id"
                  ></multiselect>
                </div>
              </div>
              <!-- Todo: Multi Form Selection -->
              <div
                class="col-md-6"
              >
                <div class="form-inline justify-content-center">
                  <label class="me-2 mt-2">Select Forms: </label>
                  <multiselect
                    v-model="chartSelectedForms"
                    :multiple="true"
                    :options="
                      getUniqueFormsTypes(
                        getRelevantForms(chartSelectedClients)
                      )
                    "
                    label="title"
                    track-by="id"
                    :disabled="chartSelectedClients.length === 0"
                  ></multiselect>
                </div>
              </div>

              <div class="col-12" v-if="chartSelectedClients.length > 0">
                <div class="form-inline float-end">
                  <button
                    class="btn btn-sm btn-danger mt-3 ms-3 "
                    @click="resetData()"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto my-auto"></div>
              <div class="col-auto ms-auto my-auto"></div>
            </div>
          </div>

          <div
            class="'card-body bg-white'"
            v-if="chartSelectedClients"
            id="report"
          >
            <h3>{{ chartSelectedClients.name }}</h3>
            <div class="row my-4 text-center">
              <div class="col">
                <div class="container noprint">
                  <perfChart :data="chartdata" ref="performanceChart" />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-10 offset-md-1">
                <small>
                  <table class="table">
                    <tr>
                      <th>Date Completed</th>
                      <th>Client</th>
                      <th>Form</th>
                      <th>Overall Score</th>
                    </tr>
                    <tr
                      v-for="(form, i) in selectedForms"
                      :key="i"
                    >
                      <td>{{ form.client_response_date }}</td>
                      <td>{{ form.name }}</td>
                      <td>{{ form.title }}</td>
                      <td>{{ form.score }}</td>
                    </tr>
                  </table>
                </small>
                <div class="text-center noprint pb-3">
                  <button
                    class="btn-lg btn btn-info me-3"
                    @click="printReport()"
                  >
                    <i class="fas fa-print"></i> Print
                  </button>
                  <download-csv
                    class="btn-lg btn btn-info"
                    :data="scoredItemData"
                    :name="'Report_' + now + '.csv'"
                    v-if="chartReady"
                  >
                    <i class="fas fa-print"></i> Export
                  </download-csv>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import perfChart from "./Chart";
import Multiselect from "vue-multiselect";
import DaterangePicker from "./DaterangePicker";

export default {
  data() {
    return {
      selectedPerfItem: [],
      chartSelectedClients: [],
      chartSelectedForms: [],
      chartdata: [],
      chartReady: false,
      chartReadyToPrint: false,
      scoredItemData: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    resetData() {
      this.selectedPerfItem = [];
      this.chartSelectedClients = [];
      this.chartSelectedForms = [];
      this.chartdata = [];
      this.chartReady = false;
      this.chartReadyToPrint = false;
      this.scoredItemData = null;
    },
    loadFormData() {
      //load form data json from backend for given date range
      var start = moment(this.dateRange.startDate);
      var end = moment(this.dateRange.endDate);

      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/reports/form-requests?start=" +
            start +
            "&end=" +
            end
        )
        .then(({ data }) => {
          this.$stash.reportData.allForms = data;
        });
    },
    scoredItems() {
      let forms = this.$stash.reportData.allForms;
      let scoredForms = forms.filter(function (f) {
        return f.score > 0;
      });

      return scoredForms;
    },
    scoredItemsClient(client) {
      let forms = this.$stash.reportData.allForms;
      forms = forms.filter(function (f) {
        return f.score > 0 && f.client_id == client.id;
      });

      return forms;
    },
    scoredClients() {
      let items = this.scoredItems();
      var clients = [];
      var keys = [];
      Array.from(items).forEach(function (f) {
        if (keys.indexOf(f.client_id) === -1 && f.score > 0) {
          clients.push({'id': f.client_id, 'name': f.name});
          keys.push(f.client_id);
        }
      });
      return clients;
    },
    getRelevantForms(clients) {
      var forms = [];
      clients.forEach((client) => {
        let data1 = this.scoredItemsClient(client);

        for (let i = 0; i < data1.length; i++) {
          forms.push(data1[i]);
        }
      });

      forms = forms.sort(
        (b, a) =>
          new Date(b.client_response_date) - new Date(a.client_response_date)
      );

      return forms;
    },
    getSelectedForms(forms) {
      return forms.filter((c) => {
        return this.chartSelectedForms.some(
          (el) => el.form_template_id === c.form_template_id
        );
      });
    },
    getUniqueFormsTypes(forms) {
      let unique = forms.filter(
        (form, index, self) =>
          index ===
          self.findIndex((t) => t.form_template_id === form.form_template_id)
      );
      return unique;
    },
    loadPerformanceData() {
      let forms = this.getSelectedForms(
        this.getRelevantForms(this.chartSelectedClients)
      );
      this.scoredItemData = forms;
      var data = [];
      this.chartdata = [];
      this.selectedPerfItem = [];
      let vm = this;

      this.chartSelectedClients.forEach(function (client) {
        forms.forEach(function (form, index) {
          if (form.client_id === client.id) {
            vm.selectedPerfItem[index] = true;
            let ndate = new Date(form.client_response_date);
            let formatDate =
              ("0" + ndate.getDate()).slice(-2) +
              "/" +
              ("0" + (ndate.getMonth() + 1)).slice(-2) +
              "/" +
              ndate.getFullYear();
            data.push({ x: formatDate, y: form.score });
          }
        });
        vm.chartdata.push([client.name, data]);
        data = [];
      });


      if (this.$refs.performanceChart) {
        this.$nextTick(() => {
          this.$refs.performanceChart.updateChart();
          this.chartReadyToPrint = true;
          this.chartReady = true;
        });
      }
    },
    printReport() {
      this.$nextTick(() => {
        const element = window.document.getElementById("report");
        let chartCanvas = document.getElementById("line-chart");
        const chart =
          "<img src='" + chartCanvas.toDataURL("image/png") + "' />";

        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }

        const win = window.open(
          "",
          "_blank",
          "toolbar=0,scrollbars=0,status=0",
          true
        );
        win.document.write(`
        <html>
          <head>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            ${stylesHtml}
            <title>Document Preview</title>
          </head>
          <body>
            ${chart}
            ${element.innerHTML}
          </body>
        </html>
      `);
        setTimeout(() => {
          win.document.close();
          win.focus();
          win.print();
          win.close();
          cb();
        }, 1000);
      });
    },
  },
  mounted() {
    this.dateRange.startDate = moment().subtract(1, "year").startOf('day');;
    this.dateRange.endDate = moment().startOf('day');;
    this.loadFormData();
  },
  watch: {
    dateRange: function () {
      this.loadFormData();
    },
    selectedForms: function () {
      this.loadPerformanceData();
    },
    chartSelectedClients: function() {
      if(this.chartSelectedClients.length === 0) {
        this.chartSelectedForms = [];
      }
    }
  },
  computed: {
    now() {
      return moment().toDate();
    },
    selectedForms() {
      return this.getSelectedForms(
        this.getRelevantForms(this.chartSelectedClients)
      );
    },
  },
  components: {
    perfChart,
    Multiselect,
    DaterangePicker,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
</style>